<template>
  <div class="app-container" style="margin-left:30px;">
     <el-form :inline="true" class="demo-form-inline">
      <el-form-item label="日期">
       <el-date-picker
        v-model="timeValue"
        type="daterange"
        align="right"
        unlink-panels
        value-format="timestamp"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :default-time="['00:00:00','23:59:59']"
      ></el-date-picker>
      </el-form-item>
      <el-form-item >
        <el-button type="primary" icon="el-icon-search" plain round @click="search">搜索</el-button>
      </el-form-item>
    </el-form>
    <div style="width:100%;height:300px;box-shadow: 1px 1px 10px #ccc;margin-top:20px;padding-top:10px;">
      <lineChart :name="name" :tableData="tableData" />
    </div>
    <!-- 表格 -->
    <div style="margin-top:20px;">
      <el-table
        :data="tableData"
        border
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        style="width:100%;box-shadow: 1px 1px 10px #ccc;"
        height="350"
        :row-class-name="tableRowClassName"
      >
        <el-table-column prop="index" label="序号"></el-table-column>
        <el-table-column prop="time" label="日期"></el-table-column>
        <el-table-column prop="wx" label="微信付费"></el-table-column>
        <el-table-column prop="ali" label="支付宝付费"></el-table-column>
        <el-table-column prop="total" label="总计"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import lineChart from "../../components/figure/lineChart.vue";
export default {
  components: {
    lineChart
  },
  data() {
    return {
      loading:true,
      name: "系统日报",
      timeValue: null, //选中的日期
      // 日期选择开始
      tableData: [] //表格数据
    };
  },
  created(){
    let time= new Date().setHours(0,0,0,0);
    let ThreeDayAgo = time - 86400000 * 3;
    this.timeValue=[ThreeDayAgo,time];
     this.getShopDaily();
  },
  methods: {
    //获取商户日报
    getShopDaily() {
      let data = {
        timeStempStart: this.timeValue[0],
        timeStempEnd: this.timeValue[1]
      };
      this.$axios
        .get("/reportForm/sysChargeByDayLimits", { params: data })
        .then(res => {
          this.loading=false;
          if (res.data.state === 1) {
            let index = 0;
            res.data.charge.reverse().map(item => {
              item.index = ++index;
              item.wx = item.wx / 100;
              item.ali = item.ali / 100;
              item.yz = item.yz / 100;
              item.total = item.total / 100;
              return item;
            });
            this.tableData = res.data.charge;
          }
        });
    },
    //搜索
    search() {
      this.getShopDaily();
    },
     // 表格颜色
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "success-rows";
      }
      return "";
    },
  }
};
</script>

<style>
.el-table td {
  text-align: center;
  padding: 10px 0;
}
.el-table th {
  padding: 5px 0;
  text-align: center;
}
</style>